const CustomCard = ({ children, className, ...props }) => {
  return (
    <div
      className={`shadow-card-shadow border-[rgba(233, 233, 233, 0.76)] border-[1px] rounded-[10px] ${className} `}
      {...props}
    >
      {children}
    </div>
  );
};
export default CustomCard;
