import React, { useRef } from "react";
import Slider from "react-slick";
import Image from "next/image";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import One from "../../public/images/sliderImages/slider-1.png";
import Two from "../../public/images/sliderImages/slider-2.png";
import Three from "../../public/images/sliderImages/slider-3.png";
import Four from "../../public/images/sliderImages/slider-4.png";
import Five from "../../public/images/sliderImages/slider-5.svg";
import Six from "../../public/images/slider-4.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BGContainer from "../CustomComponents/BGImageContainer";
import CustomButton from "../CustomComponents/CustomFormElements/CustomButton";
import CustomTitle from "../CustomComponents/CustomTitle";

const sliderData = [
  {
    href: "#howitworks",
    src: One,
    title:
      "Forget coordinating with multiple banks to meet your financial needs",
    text: "We bring you the lowest interest offers from multiple banks at one place and assist you at every step till disbursal.",
    inetrestRate: "Services are provided free of charge",
    applyNow: "Know More",
    // extra: "Study abroad dreams.",
  },
  {
    href: "/loan?type=personal",
    src: Two,
    title: "Personal Loan",
    text: "Fulfill your needs instantly with a personal loan from our partner banks at the best terms!",
    inetrestRate: "Interest Rate Starting @ 10.35%",
    applyNow: "Apply Now",
    extra: "Study abroad dreams.",
  },
  {
    href: "/loan?type=business",
    src: Three,
    title: "Business Loan",
    text: "Power your business to new heights with Business loans at the best terms ",
    inetrestRate: "Interest Rate Starting @ 13%",
    applyNow: "Apply Now",
    extra: "Study abroad dreams.",
  },
  {
    href: "/loan?type=home",
    src: Four,
    title: "Home Loan",
    text: "Own your home just the way you dreamt and leave its financing to us.",
    inetrestRate: "Interest Rate Starting @ 8.5%",
    applyNow: "Apply Now",
    extra: "Study abroad dreams.",
  },

  // {
  //   href: "/",
  //   src: Four,
  //   title: "Credit Card",
  //   text: "Maximizing Rewards, Finding the Best Credit card for Your Lifestyle",
  //   extra: "Study abroad dreams.",
  // },
  {
    href: "/loan?type=credit",
    src: Five,
    title: "Credit Score",
    text: "Check your Credit score for free and know your loan eligibility ",
    inetrestRate: "Get personalized loan offers",
    applyNow: "Check Now",
    extra: "Study abroad dreams.",
  },
  {
    href: "/loan?type=creditcard",
    src: Six,
    title: "Credit Card",
    text: "Assured eligibility for the best card for your profile.",
    inetrestRate: "Get life time FREE cedit card",
    applyNow: "Apply Now",
    extra: "Study abroad dreams.",
  },
];

const MainSlider = () => {
  const sliderRef = useRef();
  const router = useRouter();

  const sliderProps = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    lazyLoad: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  const mobileProps = {
    ...sliderProps,
    dots: true,
    arrows: false,
    appendDots: (dots) => <ul>{dots}</ul>,
  };

  const largeScreenProps = {
    ...sliderProps,
    dots: true,
  };

  return (
    <>
      {/* Web Screen */}
      <div className="relative hidden sm:block">
        <BGContainer>
          <Slider
            className="banner w-full"
            {...largeScreenProps}
            ref={sliderRef}
          >
            {sliderData.map((slide) => {
              return (
                <div
                  className={`mx-auto !grid !w-[86vw] items-center justify-items-center pt-5 md:h-[350px] md:grid-cols-3 md:gap-0`}
                  key={slide.title}
                >
                  <div className="col-span-2 justify-self-start">
                    {slide.inetrestRate && (
                      <p className="sub-text2 w-fit rounded-[80px] bg-[#f2b3c954] px-4 py-2">
                        {slide.inetrestRate}
                      </p>
                    )}
                    {/* Interest Rate Starting @ 8.3% */}

                    <p className="mt-3 mb-[14px] text-3xl font-bold">
                      {slide.title}
                    </p>

                    <p className="text-xl text-gray-600">{slide.text}</p>

                    <CustomButton
                      buttonType={"pink"}
                      onClick={() => {
                        // console.log(router.pathname + slide.href);
                        router.push(slide.href);
                        window.scrollTo("#howitworks", 200);
                      }}
                      className="button-large my-3 px-4 py-3"
                    >
                      {slide.applyNow}
                      <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
                    </CustomButton>
                  </div>
                  <Image
                    alt="One"
                    src={slide.src}
                    className="h-[200px] w-auto justify-self-end md:h-[303px]"
                  />
                </div>
              );
            })}
          </Slider>
          <div className="my-[120px] flex flex-col items-center ">
            <span className="flex-grow border-t border-[#0000001a]"></span>
            <CustomTitle
              className={
                "mx-auto w-[60vw] px-5 text-center text-xl font-bold sm:text-3xl"
              }
            >
              Your money is precious, and you deserve the loan at the best terms
              when you need it the most.
            </CustomTitle>
            <span className="mt-3 flex-grow border-t border-[#0000001a]"></span>

            <p
              className="rounded-[30px] bg-button-pink py-[1px] px-3 text-xs text-white sm:px-6 sm:text-xl"
              id="ourservices"
            >
              Our services are provided free of any fees/service charge
            </p>
          </div>
        </BGContainer>
      </div>
      {/* Mobile Screen */}
      <div className="sm:hidden">
        <BGContainer>
          <Slider className="w-full pt-6" {...mobileProps}>
            {sliderData.map((slide, index) => {
              return (
                <div
                  className={`relative mx-auto !grid min-h-[160px] !w-[94vw] grid-cols-7 items-center justify-items-center`}
                  key={slide.title}
                >
                  <div className="col-span-4 text-left">
                    {index !== 0 && (
                      <div className="w-fit rounded-[80px] bg-[#f2b3c954] px-2 py-1 text-[10px] leading-[20px]">
                        {slide.inetrestRate}
                        {/* Interest Rate Starting @ 8.45% */}
                      </div>
                    )}

                    <p className="my-1 text-base font-bold">{slide.title}</p>
                    {index !== 0 && (
                      <p className="body4 mt-1  text-text-gray">{slide.text}</p>
                    )}
                    <CustomButton
                      buttonType={"pink"}
                      className="mt-3 px-3 py-2.5 text-base font-bold !font-bold"
                      onClick={() => {
                        router.push(slide.href);
                        window.scrollTo("#howitworks", 650);
                      }}
                    >
                      {slide.applyNow}
                      <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
                    </CustomButton>
                  </div>

                  <Image
                    alt="One"
                    src={slide.src}
                    className="col-span-3 h-auto w-full"
                  />
                </div>
              );
            })}
          </Slider>

          <CustomTitle
            className={
              "h6 mx-auto mt-20 mb-3 w-[94vw] border-y-[1px] border-[#F1F5F9] text-center"
            }
            id="ourservicesMobile"
          >
            Your Money is Precious, and you Deserve the Loan at the Best Terms
            When you Need it the Most.
          </CustomTitle>
          <p
            className="mx-auto mb-6 w-[94vw] rounded-[30px] bg-button-pink py-[1px] text-center text-xs text-white"
            id="ourservices"
          >
            Our services are provided free of any fees/service charge
          </p>
        </BGContainer>
      </div>
    </>
  );
};

export default MainSlider;
