import React from "react";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";
import CustomButton from "../CustomComponents/CustomFormElements/CustomButton";
import whyfingomini from "../../public/images/whyfingomini.svg";
import Rec1 from "../../public/images/recommended-1.png";
import Rec2 from "../../public/images/recommended-2.png";
import MobilePic from "../../public/images/LandingPage/phone.png";
import CalculatorPic from "../../public/images/LandingPage/calculator.png";
import CreditPic from "../../public/images/LandingPage/credit.png";
import { motion } from "framer-motion";

const WhyFingo = () => {
  const router = useRouter();

  const cardVariants1 = {
    offscreen: {
      x: -60,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      rotate: 0,
      transition: {
        duration: 1,
      },
    },
  };
  const cardVariants2 = {
    offscreen: {
      x: 60,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      rotate: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const AnimateComp = ({ children, className, cardVariants }) => {
    return (
      <motion.div
        initial="offscreen"
        className={className}
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <motion.div variants={cardVariants}>{children}</motion.div>
      </motion.div>
    );
  };

  return (
    <>
      {/* blue banner for both web and mobile*/}
      <div className="ml-[-3vw] mt-[36px] grid w-screen rotate-[1deg] justify-between bg-nav-blue px-[3vw] py-[12px] pr-0 sm:ml-[-7.9vw] sm:mt-[100px] sm:grid-cols-2 sm:px-[7vw]">
        <div className="relative rotate-[-1deg] flex-col justify-around justify-self-start py-[10px] px-4 text-center sm:flex sm:w-full sm:py-10 sm:text-start">
          <p className="mx-auto mb-[6px] text-xl font-bold text-[#b6b6ff]  sm:mb-4 sm:text-5xl">
            Confused about which bank to choose for your loan? We are here to
            help
          </p>
          <p className="relative !z-50 mb-3 text-left text-center text-xs text-[#7e7dd6] sm:mb-9  sm:text-start sm:text-xl sm:font-normal">
            We provide customised recommendations and end to end support until
            disbursal.
          </p>
          <CustomButton
            buttonType={"white"}
            className="sm:hidden w-fit !rounded-lg bg-white px-4 !text-base !font-bold text-nav-blue transition delay-200 duration-200 hover:bg-button-pink hover:text-white"
            onClick={() => router.push("#ourservicesMobile")}
          >
            Apply Now
            <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
          </CustomButton>
          <CustomButton
            buttonType={"white"}
            className="hidden sm:block w-fit !rounded-lg bg-white px-4 !text-base !font-bold text-nav-blue transition delay-200 duration-200 hover:bg-button-pink hover:text-white"
            onClick={() => router.push("#ourservices")}
          >
            Apply Now
            <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
          </CustomButton>
        </div>
        <div className="mx-auto hidden h-[350px] w-[350px] justify-center place-self-center bg-[url('/images/LandingPage/rings.png')] bg-cover bg-no-repeat sm:relative sm:flex">
          <div className="flex h-3/4 place-self-center">
            <AnimateComp
              className="place-self-end"
              cardVariants={cardVariants1}
            >
              <Image
                src={CalculatorPic}
                alt="CalculatorPic"
                className="h-[80px] w-auto animate-wiggle"
              />
            </AnimateComp>
          </div>
          <Image
            src={MobilePic}
            alt="Mobile"
            className="absolute h-full w-auto place-self-center justify-self-center"
          />
          <div className="place-self-center">
            <AnimateComp cardVariants={cardVariants2}>
              <Image
                src={CreditPic}
                alt="CreditPic"
                className="ml-[120px] h-[80px] w-auto animate-wiggle"
              />
            </AnimateComp>
          </div>
        </div>
      </div>
      {/* why fingo image for both web and mobile*/}
      <div className="my-10 ml-[-7.6vw] flex w-[100vw] flex-col items-center gap-6 text-center">
        <Image
          src={whyfingomini}
          className="ml-[-30vw] h-auto w-full"
          alt="Why Fingo"
        />
      </div>
      {/* pink banner for both web and mobile*/}
      <div className="ml-[-3vw] w-screen rotate-[1deg] justify-between bg-button-pink px-[3vw] py-[12px] pr-0 text-center sm:ml-[-7.9vw] sm:grid sm:grid-cols-2 sm:py-0 sm:px-[8vw] sm:text-start">
        <div className="relative rotate-[-1deg] flex-col justify-around justify-self-start py-[10px] sm:flex sm:w-full sm:py-20">
          <p className="mx-auto mb-[6px] text-xl font-bold text-[#fbe7ee]  sm:mb-4 sm:text-5xl">
            Running a business is difficult but getting a loan need not be
          </p>
          <p className="relative !z-50 mb-3 text-left text-center text-xs text-[#FBE7EE] sm:mb-9  sm:text-start sm:text-xl sm:font-normal">
            Our expert recommendations will address all your business needs in
            minutes
          </p>

          <CustomButton
            buttonType={"white"}
            className="sm:hidden w-fit !rounded-lg bg-white px-4 !text-base !font-bold text-button-pink transition delay-200 duration-200 hover:bg-nav-blue hover:text-white"
            onClick={() => router.push("#ourservicesMobile")}
          >
            Apply Now
            <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
          </CustomButton>

          <CustomButton
            buttonType={"white"}
            className="hidden sm:block w-fit !rounded-lg bg-white px-4 !text-base !font-bold text-button-pink transition delay-200 duration-200 hover:bg-nav-blue hover:text-white"
            onClick={() => router.push("#ourservices")}
          >
            Apply Now
            <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
          </CustomButton>
        </div>
        <div className="bg-[url('/images/LandingPage/mobiles.png')] bg-cover bg-right"></div>
      </div>
    </>
  );
};
export default WhyFingo;
