import React from "react";
import Image from "next/image";
import { motion } from "framer-motion";

import CustomTitle from "../CustomComponents/CustomTitle";
import One from "../../public/images/HowIWorks/works-1.svg";
import Two from "../../public/images/HowIWorks/works-2.svg";
import Three from "../../public/images/HowIWorks/works-3.svg";
import Four from "../../public/images/HowIWorks/works-4.svg";

const HowItWorks = () => {
  const cardVariants = {
    offscreen: {
      y: 300,
    },
    onscreen: {
      y: 50,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1.5,
      },
    },
  };

  const ImageComp = ({ src, alt, width, height, ...styles }) => {
    return (
      <Image
        alt={alt}
        src={src}
        width={width ? width : "180"}
        height={height ? height : "180"}
        {...styles}
      />
    );
  };

  const AnimateComp = ({ children }) => {
    return (
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <motion.div variants={cardVariants}>{children}</motion.div>
      </motion.div>
    );
  };

  return (
    <>
      {/* Mobile layout */}
      <div className="mt-24 text-center sm:!mt-0">
        <CustomTitle
          className={"mx-auto w-[50vw] text-xl sm:text-5xl sm:leading-[48px]"}
        >
          How It works
        </CustomTitle>
        <p className="m-auto mb-3 mt-2 w-24 border-b-[1px] font-bold sm:mb-6 sm:w-48 sm:border-b-[2px]"></p>
        <p className="text-base font-bold text-nav-blue sm:text-4xl">
          Apply for a loan/card
          <br /> of your choice
        </p>
        <div className="timeline grid grid-cols-2 items-center gap-y-6  sm:gap-y-12 ">
          <div className="absolute top-0 left-[50%] col-span-2 -ml-2.5">
            {/* <div className="w-4 h-4 bg-nav-blue rounded-2xl"></div> */}
          </div>

          <AnimateComp>
            <div className="ml-auto flex w-[85%] flex-col items-end pr-5 text-right sm:pr-9">
              <div className="absolute top-0.5 -right-2 flex h-6 w-6 items-center justify-center rounded-full bg-[#D60B52] text-xs font-medium text-white shadow-xl sm:-right-5 sm:h-12 sm:w-12 sm:text-xl ">
                1
              </div>

              <p className="text-base font-bold text-nav-blue sm:text-2xl">
                We evaluate your credit profile just the way the banks do
              </p>
            </div>
          </AnimateComp>
          <AnimateComp>
            {/* <div className="absolute left-0 -ml-0.5 w-0.5 h-full bg-gray-600"></div> */}
            <div className="flex flex-col items-center justify-center pl-4 sm:pl-9">
              <ImageComp
                alt="Credit"
                src={One}
                className="mr-auto h-[110px] w-auto sm:h-[220px]"
              />
            </div>
          </AnimateComp>
          <AnimateComp>
            {/* <div className="absolute right-0 -ml-0.5 w-0.5 h-full bg-gray-600"></div> */}
            <div className="flex flex-col items-center justify-center pr-9">
              <ImageComp
                alt="Online"
                src={Two}
                className="ml-auto h-[110px] w-auto sm:h-[220px]"
              />
            </div>
          </AnimateComp>
          <AnimateComp>
            <div className="mr-auto w-[85%] pl-9 text-left">
              <div className="absolute top-0.5 -left-[14px] flex h-6 w-6 items-center justify-center rounded-full bg-[#D60B52]  text-xs font-medium text-white shadow-xl sm:-left-[26px]  sm:h-12 sm:w-12 sm:text-xl">
                2
              </div>
              <p className="text-base font-bold text-nav-blue sm:text-2xl">
                Assess the best loan options for your profile
              </p>
            </div>
          </AnimateComp>
          <AnimateComp>
            <div className="ml-auto flex w-[85%] flex-col items-end pr-9 text-right">
              <div className="absolute top-0.5 -right-2 flex h-6 w-6 items-center justify-center rounded-full bg-[#D60B52] text-xs font-medium text-white shadow-xl sm:-right-5 sm:h-12 sm:w-12 sm:text-xl ">
                3
              </div>
              <p className="text-base font-bold text-nav-blue sm:text-2xl">
                We negotiate for the best terms on your behalf
              </p>
            </div>
          </AnimateComp>
          <AnimateComp>
            {/* <div className="absolute left-0 -ml-0.5 w-0.5 h-full bg-gray-600"></div> */}
            <div className="flex flex-col items-center justify-center pl-9">
              <ImageComp
                alt="Credit"
                src={Three}
                className="mr-auto h-[110px] w-auto sm:h-[220px]"
              />
            </div>
          </AnimateComp>
          <AnimateComp>
            <div className="flex flex-col items-center justify-center pr-9">
              <ImageComp
                alt="Credit"
                src={Four}
                className="ml-auto h-[110px] w-auto sm:h-[220px]"
              />
            </div>
          </AnimateComp>
          <AnimateComp>
            <div className="mr-auto w-[85%] pl-9 text-left">
              <div className="absolute top-0.5 -left-[14px] flex h-6 w-6 items-center justify-center rounded-full bg-[#D60B52] text-xs font-medium text-white shadow-xl sm:-left-[26px] sm:h-12 sm:w-12 sm:text-xl ">
                4
              </div>

              <p className="text-base font-bold text-nav-blue sm:text-2xl">
                We assist you with documentation and provide dedicated support
                until final disbursal
              </p>
            </div>
          </AnimateComp>
        </div>
        <p className="my-3 text-xl font-bold text-nav-blue sm:my-6 sm:text-4xl">
          Loan is disbursed
        </p>
        <CustomTitle className={"!text-lg font-bold sm:!text-4xl"}>
          AND YES, WE DO ALL THIS FREE OF COST TO YOU.
        </CustomTitle>

        {/* <div className="sm:hidden bg-nav-blue text-center w-screen ml-[-3vw] mt-12 py-[22px] px-[44px] flex flex-col items-center justify-around">
          <p className="text-white text-2xl font-bold mb-4">
            We recommend best bank loan provider
          </p>
          <Link
            className="bg-white font-semibold rounded-[132px] px-4 py-3.5 w-fit text-sm border-[1px] border-black"
            href="/login"
          >
            Apply Now
            <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
          </Link>
        </div> */}
      </div>
    </>
  );
};
export default HowItWorks;
