const CustomTitle = ({ children, className, ...props }) => {
  //Tilte with gradient ext color
  return (
    <>
      <p
        className={`h3 text-transparent bg-clip-text bg-gradient-to-r from-[#292799] to-[#F20158] ${className}`}
        {...props}
      >
        {children}
      </p>
    </>
  );
};
export default CustomTitle;
