import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Slider from "react-slick";

import { faStar } from "@fortawesome/free-solid-svg-icons";

import CustomTitle from "../CustomComponents/CustomTitle";
import ReviewsPic from "../../public/images/reviews.png";

const AboutUsFlip = () => {
  const sliderProps = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    lazyLoad: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    autoplay: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => <ul>{dots}</ul>,
  };

  const Icons = () => {
    return (
      <div className="mt-2">
        <FontAwesomeIcon icon={faStar} color="#FFCE31" />
        <FontAwesomeIcon icon={faStar} color="#FFCE31" />
        <FontAwesomeIcon icon={faStar} color="#FFCE31" />
        <FontAwesomeIcon icon={faStar} color="#FFCE31" />
        <FontAwesomeIcon icon={faStar} color="#FFCE31" />
      </div>
    );
  };
  const aboutusdata = [
    {
      commentBody:
        "My loan got disbursed in less than a day, Thanks to Fingos excellent customer service, Whenever I needed something they were there for me throughout the process, truly empathetic. Thank you guys.",
      commentTitle: "Anitha Sharma",
    },
    {
      commentBody:
        "I need not roam across different banks to find the best loan option for me. Fingo presented me the best suitable options customised to my credit profile that meets my financial requirement.",
      commentTitle: "Ravi shankar",
    },
    {
      commentBody:
        "I was really disappointed when my loan got rejected from a couple of banks. When I consulted Fingo, it helped me get to the right bank based on my profile, I got my loan disbursed - all this at ZERO COST.",
      commentTitle: "Sandeep kumar",
    },
    {
      commentBody:
        "Oohoo! When I applied for a loan through Fingo, Fingo negotiated with the bank on my loan amount eligibility and interest rate on my behalf, and helped me get the loan at the best terms.",
      commentTitle: "Himanshu Prajay",
    },
  ];
  return (
    <>
      <div className={"text-center sm:mb-[48px]"}>
        <CustomTitle className="mx-auto mt-[12px] mb-[24px] text-xl font-bold sm:mt-[48px] sm:w-[40%] sm:text-4xl">
          Our love board
        </CustomTitle>
        <Slider className="w-full" {...sliderProps}>
          {aboutusdata.map((item, index) => (
            <div key={index} className="!flex flex-col">
              <div className="!relative !mx-auto">
                <Image
                  src={ReviewsPic}
                  alt={"ReviewsPic"}
                  className="h-[200px] w-auto sm:h-[260px]"
                />

                {/* <p className="!absolute top-8 px-8 pr-10 pl-10 text-center text-xs font-medium sm:top-10 sm:text-base sm:font-semibold"> */}
                <p className="!absolute top-9  px-12 text-center text-xs font-medium sm:top-10 sm:text-base sm:font-semibold">
                  {item.commentBody}
                </p>
              </div>
              <Icons />
              <p className="text-sm font-semibold sm:text-lg sm:font-bold">
                {item.commentTitle}
              </p>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
export default AboutUsFlip;
