const BGContainer = ({ children, className }) => {
  ///compoent to provide background image to full body width
  return (
    <div
      className={`bg-bg_image_1 bg-cover w-screen h-full  ml-[-3vw] sm:ml-[-7.6vw] ${className}`}
    >
      <div className="mx-auto">{children}</div>
    </div>
  );
};
export default BGContainer;
