import { useEffect } from "react";
import HowItWorks from "../components/LandingPages/HowItWorks";
import WhyFingo from "../components/LandingPages/WhyFingo";
import AboutUsFlip from "../components/LandingPages/AboutusFlip";
import Achievements from "../components/LandingPages/Achievements";
import PartnerSlider from "../components/LandingPages/PartnersSlider";
import MainSlider from "../components/LandingPages/MainSlider";
import { useRouter } from "next/router";
import { getUser } from "../utils/utility";
import LatestBlog from "../components/LandingPages/LatestBlog";
import FingoServices from "../components/LandingPages/FingoServices";
import { clear } from "../utils/localstorage";
import CustomModal from "../components/CustomComponents/CustomModal";
import { useState } from "react";
import Image from "next/image";
import landingmodal from "../public/images/landingmodal.png";
export default function Home() {
  const router = useRouter();
  const [open, isOpen] = useState(true);

  useEffect(() => {
    getUser() && router.push("/dashboard");
    !getUser() && clear();
  }, []);

  return (
    <div className="">
      <MainSlider />
      <FingoServices />
      <PartnerSlider />
      <HowItWorks />
      {/* <Achievements /> */}
      <WhyFingo />
      <AboutUsFlip />
      {/* <LatestBlog /> */}
      {/* <CustomModal
        onClose={() => isOpen(false)}
        isClosable={true}
        open={open}
        className="bg-gradient-to-r from-[#fff] to-[#ECECFF]"
        backgroundConfetti={true}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={landingmodal}
          className="h-full w-auto flex"
          height={"50px"}
          width={"50px"}
          alt="landingmodal"
          style={{ margin: "auto" }}
        />
      </CustomModal> */}
    </div>
  );
}
