import styles from "../../styles/Home.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";

const PartnerSlider = () => {
  const partnerSlides = [
    "sbi",
    "union",
    "bob",
    "lic",
    "indian",
    "hdfc",
    "icici",
    "axis",
    "yes",
    "hdfc-credella",
    "bajaj",
    "piramila",
    "fulletron",
    "lending",
  ];

  const sliderPartnerProps = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <>
      {/* {web screen} */}
      <div className="relative my-[120px] hidden rounded-[12px] bg-gradient-to-r from-[#292799] to-[#F20158] py-5 sm:block md:h-[135px] md:p-5">
        <p className="h3 text-center text-white">
          Our Official Banking and NBFC Partners
        </p>
        <div
          className="absolute inset-0 top-[90px] mx-auto grid h-[124px] w-11/12 grid-cols-1 rounded bg-[#fff] shadow-nav-shadow"
          id="howitworks"
        >
          <Slider className="m-auto w-11/12" {...sliderPartnerProps}>
            {partnerSlides.map((slide) => (
              <div className="!flex h-[124px] items-center" key={slide}>
                <Image
                  key={slide}
                  alt={`Partner-${slide}`}
                  src={`/images/finalBanks/${slide}.svg`}
                  width={100}
                  height={100}
                  className="h-auto !w-[120px] place-self-center"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* Mobile Screen */}
      <div className="relative h-[72px] rounded bg-gradient-to-r from-[#292799] to-[#F20158] py-[18px] px-2.5 sm:hidden">
        <p className="h6 text-center text-white">
          Our Official Banking and NBFC Partners
        </p>
        <div className="absolute inset-0 top-[50px] mx-auto grid h-[100px] w-11/12 grid-cols-1 rounded bg-[#fff] shadow-partner-shadow">
          <Slider className="m-auto w-11/12" {...sliderPartnerProps}>
            {partnerSlides.map((slide) => (
              <div
                className={`!flex h-[100px] items-center justify-center`}
                key={slide}
              >
                <Image
                  alt={`Partner-${slide}`}
                  src={`/images/finalBanks/${slide}.svg`}
                  width="100"
                  height="100"
                  className="h-auto w-[100px] place-self-center"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};
export default PartnerSlider;
