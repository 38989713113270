import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { fingoServices } from "../../utils/constants";

const FingoServices = () => {
  const router = useRouter();

  return (
    <>
      {/* web screen */}
      <div className="hidden sm:block">
        <p className="text-2xl font-bold">Our Services</p>
        <div className="mt-6 grid grid-cols-1 gap-[53px] sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          {fingoServices.map((entry, index) => (
            <div
              key={index}
              className="relative min-h-[200px] rounded border-b-[3px] border-button-pink bg-white py-3 px-4 shadow-web-service-shadow hover:scale-105 hover:shadow-service-shadow-hover"
            >
              <div className="flex justify-between">
                <p className="text-base font-semibold text-nav-blue">
                  {entry?.title}
                </p>
                <div className="h-10 w-10 rounded bg-[#F1F5F9] p-1">
                  <Image
                    src={`/images/servicesIcons/${entry?.src}.svg`}
                    width="50"
                    height="50"
                    alt={`service-${index + 1}`}
                  />
                </div>
              </div>
              <p className="mt-2 mb-12 w-3/4 text-xs text-[#475569]">
                {entry?.body}
              </p>
              <Link
                className={
                  "absolute bottom-3 text-xs font-bold text-button-pink underline underline-offset-4" +
                  (entry?.disable ? " !pointer-events-none" : "")
                }
                href={entry?.href}
              >
                {/* Apply Now */}
                {entry?.fingoServicesApplyNow}
                <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      {/* Mobile Screen */}
      <div className="mt-[18px] mb-6 grid w-full grid-cols-3 gap-6 sm:hidden">
        <div className="col-span-3 text-xl font-bold">Our Services</div>
        {fingoServices.map((entry, index) => (
          <div
            onClick={() => !entry.disable && router.push(entry.href)}
            className="flex flex-col items-center gap-2 text-center"
            key={index}
          >
            <div className="flex h-[48px] w-[48px] items-center justify-center rounded-[134px] bg-white shadow-service-shadow">
              <Image
                src={`/images/servicesIcons/${entry?.src}.svg`}
                width="60"
                height="60"
                alt={`service-${index + 1}`}
                className={`h-[26px] w-[26px]`}
              />
            </div>

            <p className="text-sm text-[#475569] ">{entry.title}</p>
          </div>
        ))}
      </div>
    </>
  );
};
export default FingoServices;
